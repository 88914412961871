import * as React from "react"

import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import favicon from '../images/logo.png'
import { StaticImage} from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import BackgroundImage from 'gatsby-background-image'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Fade, Slide } from "react-awesome-reveal"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'

import 'swiper/css'

SwiperCore.use([Autoplay])

const IndexPage = () => {
	const [isOpen, setisOpen] = React.useState(false);
	function handleClick() {
		setisOpen(!isOpen);
	}

	const data = useStaticQuery(
		graphql`
		  query {
			desktop: file(relativePath: { eq: "background.jpg" }) {
			  childImageSharp {
				fluid(quality: 80, maxWidth: 1920) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
		  }
		`
	  )
	
	const imageData = data.desktop.childImageSharp.fluid


	return (
		<>
			<Helmet>
					<title>Point-Accu.id - Jasa Layanan Antar dan Pasang Aki Original</title>
					<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			<div id='home' className="flex flex-col w-full h-auto">
				<div className="navbar sticky top-0 bg-[#2e456a] w-full shadow-lg z-40 border-b border-gray-700">
					<div className="flex flex-col py-4 px-8 md:px-14">
						<div className="flex flex-row justify-between">
							<div className="logo m-1 flex items-center justify-center z-30">
								<StaticImage height={65} placeholder="none" src="../images/logo.png" alt="logo" loading='{ eager }' /><span className="text-primary font-bold text-xl md:text-2xl ml-3">POINT-ACCU.ID</span>
							</div>
							<div className="hidden lg:flex lg:flex-row font-semibold">
								<AnchorLink to='/#home' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-primary hover:text-white">
									<div className='flex-wrap content-center mr-1'>
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
											<path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
										</svg>
									</div>
									<span>Home</span>
								</AnchorLink>
								<AnchorLink to='/#produk' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-primary hover:text-white">
									<div className='flex-wrap content-center mr-1'>
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
											<path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
										</svg>
									</div>
									<span>Produk</span>
								</AnchorLink>
								<AnchorLink to='/#testimonial' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-primary hover:text-white">
									<div className='flex-wrap content-center mr-1'>
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
											<path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
											<path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
										</svg>
									</div>
									<span>Testimonial</span>
								</AnchorLink>
								<AnchorLink to='/#kontak' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-primary hover:text-white">
									<div className='flex-wrap content-center mr-1'>
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
											<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
										</svg>
									</div>
									<span>Kontak</span>
								</AnchorLink>
							</div>
							<button type="button" className="block text-primary lg:hidden" onClick={handleClick}>
								<svg className="h-6 w-6 fill-current border border-primary rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									{isOpen && (
										<path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
									)}
									{!isOpen && (
										<path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
									)}
								</svg>
							</button>
						</div>
						<div className={`transition-all duration-150 text-primary font-semibold lg:hidden ${  isOpen ? "max-h-screen visible" : "max-h-0 invisible" }`}>
							<AnchorLink to='/#home' className="flex flex-row gap-x-2 nav-item p-4 mt-4 justify-center border border-primary">
								<div className='flex flex-wrap justify-center content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
										<path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
									</svg>
								</div>
								<span>Home</span>
							</AnchorLink>
							<AnchorLink to='/#produk' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-primary">
								<div className='flex flex-wrap justify-center content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
										<path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
									</svg>
								</div>
								<span>Produk</span>
							</AnchorLink>
							<AnchorLink to='/#testimonial' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-primary">
								<div className='flex flex-wrap justify-center content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
										<path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
										<path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
									</svg>
								</div>
								<span>Testimonial</span>
							</AnchorLink>
							<AnchorLink to='/#kontak' className="flex flex-row gap-x-2 nav-item p-4 mt-2 justify-center border border-primary">
								<div className='flex flex-wrap justify-center content-center'>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-5 w-5" viewBox="0 0 16 16">
										<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
									</svg>
								</div>
								<span>Kontak</span>
							</AnchorLink>
						</div>
					</div>
				</div>

				<BackgroundImage
					Tag="section"
					className='hero'
					fluid={imageData}
					backgroundColor={`#000`}
					>
					<div className='heading-container flex flex-col justify-center items-center w-full h-full'>
						<h2 className='text-3xl md:text-4xl text-white text-center leading-7 md:leading-10'><strong>Jasa Layanan Antar dan Pasang Aki</strong></h2>
						<p className='text-xl md:text-xl text-white text-center leading-7 md:leading-10'>Gratis Jasa Antar dan Pasang Aki ke Lokasi Anda</p>
						<div className='flex flex-col text-center lg:flex-row text-white'>
							<div className='whatsapp-button m-2'>
								<OutboundLink id='whatsapp-button' href="https://api.whatsapp.com/send?phone=628118881507&text=Hallo%20Point-Accu.id" target="_blank" rel="noreferrer" className="whatsapp-button transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-500 hover:bg-green-400 font-medium py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
									<span>Whatsapp Kami</span>
								</OutboundLink>
							</div>
							<div className='phone-button m-2'>
								<OutboundLink id='call-button' href='tel:+628118881507' className="call-button w-full transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-[#2e456a] hover:bg-slate-800 font-medium py-3 px-4 rounded inline-flex items-center shadow-md">
								<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
									<span>Telepon Kami</span>
								</OutboundLink>
							</div>
						</div>
					</div>
				</BackgroundImage>

				<section id='tentang' className='w-full container mx-auto text-center py-8 px-4 mt-4 mb-8'>
					<Fade triggerOnce='true'>
						<div className="header text-3xl mx-2 mt-2 mb-6">
							<h2 className='my-2 text-primary'><strong>Tentang Kami</strong></h2>
						</div>
						<p className='text-lg'>
							<strong>Point-Accu.id</strong> adalah toko aki mobil dan motor terlengkap untuk kebutuhan kendaraan anda.
							Kami menjual produk-produk aki berkualitas, bergaransi dan dijamin original.
							Adapun produk aki yang disediakan oleh <strong>Point-Accu.id</strong> yaitu seperti aki GS Astra, Yuasa, Incoe, Solite, Amaron, Furukawa Battery, Delkor dll.
							Untuk menjaga kepercayaan pelanggan, kami berkomitmen hanya menjual produk Original sehingga menjadi toko aki terpercaya untuk anda.
						</p>
					</Fade>
				</section>

				<section id='keunggulan' className='bg-gradient-to-b from-[#2e456a] via-[#283c5c] to-[#253755] mx-auto text-center w-full px-8 py-6 md:py-8 md:px-8'>
					<div className='container mx-auto'>
						<div className="header text-3xl text-primary m-2 mb-9">
							<h2><strong>Point-Accu.id</strong></h2>
						</div>
						<div className='container mx-auto grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-4 xl:grid-cols-4 xl:gap-5 mb-6'>
							<Slide delay='30' triggerOnce='true' direction={'up'}>
								<div className='h-full bg-white border border-gray-300 shadow-md p-8'>
									<StaticImage src="../images/pasang-antar.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Gratis Jasa Antar dan Pasang</strong></h3>
									<p className='mt-3'>Point-Accu.id melayani jasa antar dan pasang ke lokasi, sehingga anda tidak perlu repot ketika kendaraan anda mogok di jalan ataupun di rumah.</p>
								</div>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<div className='h-full bg-white border border-gray-300 shadow-md p-8'>
									<StaticImage src="../images/trade-in.png" alt="accumulator" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Trade in / Tukar Tambah</strong></h3>
									<p className='mt-3'>Kami menerima tukar tambah aki lama anda dengan aki baru sehingga dapat mengurangi harga aki yang di beli.</p>
								</div>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<div className='h-full bg-white border border-gray-300 shadow-md p-8'>
									<StaticImage src="../images/garansi.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Original & Bergaransi</strong></h3>
									<p className='mt-3'>Kami menjamin aki yang kami berikan Original (bukan tiruan) dan  dipastikan aki baru, sehingga kami berikan garansi untuk aki yang anda pakai.</p>
								</div>
							</Slide>
							<Slide delay='30' triggerOnce='true' direction='up'>
								<div className='h-full bg-white border border-gray-300 shadow-md p-8'>
									<StaticImage src="../images/beragam.png" alt="delivery" width={128} height={128} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Jenis Aki Beragam</strong></h3>
									<p className='mt-3'>Adapun aki yang kami jual yaitu jenis Aki Basah (Conventional Battery), Aki Hybrid dan Aki Kering (Maintenance Free Battery)</p>
								</div>
							</Slide>
						</div>
					</div>
				</section>

				<section id='testimonial' className='container mx-auto text-center'>
					<div className='testimonial-content px-8 py-6 md:py-8 md:px-8'>
						<div className="header text-primary text-3xl m-2 mb-6 md:mb-8">
							<h2><strong>Testimonials</strong></h2>
						</div>
						<Swiper
						slidesPerView={1}
						centeredSlides={true} 
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
						>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/ibnu.png" alt="dimas" className='w-32 m-auto rounded-lg' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Ibnu</strong></h4>
									<h5 className='text-sm my-1 text-primary'>Driver Online</h5>
									<p>Aki yang diberikan oleh <strong>Point-Accu.id</strong> benar-benar aki bagus dan dalam kondisi prima, walaupun dipakai setiap hari tetap awet sehingga saya rekomendasikan ke teman-teman driver online lainnya.</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/carla.png" alt="lia" className='w-32 m-auto rounded-lg' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Carla</strong></h4>
									<h5 className='text-sm my-1 text-primary'>Pegawai Swasta</h5>
									<p>Sangat puas memakai jasa <strong>Point-Accu.id</strong>, sangat fast respon ketika saya membutuhkan aki mendadak di jalan dan team mekaniknya juga cepat datang.</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/gilang.png" alt="riki" className='w-32 m-auto rounded-lg' loading='{ eager }'/>
									<h4 className='text-xl mt-2'><strong>Gilang</strong></h4>
									<h5 className='text-sm my-1 text-primary'>Konsultan</h5>
									<p>Sudah sekian kalinya saya membeli aki di <strong>Point-Accu.id</strong> untuk beberapa mobil saya, selalu memberikan service yang baik sehingga saya puas.</p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</section>

				<section id='produk' className='bg-gradient-to-b from-[#2e456a] via-[#283c5c] to-[#253755] mx-auto text-center bg-gray-100 w-full px-8 py-6 md:py-8 md:px-8'>
					<div className="header text-primary text-3xl m-2 mb-6 md:mb-8">
						<h2><strong>Produk</strong></h2>
					</div>
					<div className='container mx-auto grid grid-cols-1 xl:grid-cols-3 justify-center items-center'>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-basah.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Aki Basah<br/>(Conventional Battery)</strong></h3>
								</div>
							</div>
						</Fade>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-hybrid.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Aki Hybrid<br/>(Hybrid Battery)</strong></h3>
								</div>
							</div>
						</Fade>
						<Fade delay='30' triggerOnce='true' direction={'bottom'}>
							<div className='produk-item'>
								<div className='bg-white border border-gray-300 shadow-md p-4 pb-8 m-3'>
									<StaticImage src="../images/aki-mf.png" alt="aki-basah" width={400} height={250} loading='{ lazy }' />
									<h3 className='text-xl'><strong>Aki Kering<br/>(Maintenance Free Battery)</strong></h3>
								</div>
							</div>
						</Fade>
					</div>
				</section>

				<section id='brand' className='mx-auto text-center bg-gray-100 w-full px-8 py-6 md:py-8 md:px-8'>
					<div className='container mx-auto m-2'>
						<Swiper
							slidesPerView={2}
							spaceBetween={50}
							autoplay={{
								"delay": 2000,
								"disableOnInteraction": false
							}}
							breakpoints={{
								1024: {
								  slidesPerView: 4,
								},
							}}
							>
								<SwiperSlide>
									<StaticImage src="../images/gsastra.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/amaron.png" alt="amaron" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/delkor.png" alt="delkor" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/faster.png" alt="faster-jp" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fasterr.png" alt="faster" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/furukawa.png" alt="furukawa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoe.png" alt="incoe" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/massiv.png" alt="massiv" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/mottobat.png" alt="mottobat" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/ngs.png" alt="ngs" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/panasonic.png" alt="panasonic" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/solite.png" alt="solite" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/varta.png" alt="varta" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa.png" alt="yuasa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
						</Swiper>
					</div>
				</section>

				<section id='kontak' className='container mx-auto text-center px-8 py-6 md:py-8 md:px-8'>
					<div className="header text-primary text-3xl m-2 mb-6 md:mb-8">
						<h2><strong>Mau Info Lebih Lanjut?</strong></h2>
					</div>
					<div className='flex flex-col mb-3'>
						<div className='flex justify-center mb-3'>
							<div className='flex flex-col text-center lg:flex-row'>
								<div className='whatsapp-button m-2'>
								<OutboundLink id='whatsapp-button' href="https://api.whatsapp.com/send?phone=628118881507&text=Hallo%20Point-Accu.id" target="_blank" rel="noreferrer" className="whatsapp-button transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-500 hover:bg-green-400 text-white font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
									<span>Whatsapp Kami</span>
								</OutboundLink>
								</div>
								<div className='phone-button m-2'>
									<OutboundLink id='call-button' href='tel:+628118881507' className="call-button w-full transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-[#2e456a] hover:bg-gray-800 text-white font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
									<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
										<span>Telepon Kami</span>
									</OutboundLink>
								</div>
							</div>
						</div>
						<div className='no-text m-2 mb-3'>
							<strong>+628118881507</strong>
						</div>
						<div className='no-text mb-6'>
							<strong> Email : pointaccu@gmail.com</strong>
						</div>

					</div>
				</section>

				<div className='footer text-center text-sm bg-gradient-to-b from-[#2e456a] via-[#283c5c] to-[#253755]'>
					<p className='text-white py-3'>&copy; 2022 <a href="https://www.point-accu.id">Point-Accu.id</a> All Right Reserved<br/>Design & Developed by <a className='text-white' href="https://www.kodemerah.com" target='_blank' rel="noreferrer">KodeMerah</a></p>
				</div>
			</div>

			<OutboundLink id='whatsapp-button' href="https://api.whatsapp.com/send?phone=628118881507&text=Hallo%20Point-Accu.id" className="wa-float whatsapp-button" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
			</OutboundLink>
			<OutboundLink id='call-button' href="tel:+628118881507" className="bg-[#2e456a] call-float call-button" rel="noreferrer">
				<FontAwesomeIcon className='call-my-float' icon={faPhone} />
			</OutboundLink>
    	</>
  )
}

export default IndexPage
